
/* For Tablet View */
@media screen and (min-device-width: 768px)
and (max-device-width: 1024px) {
  *{
    font-size:0.8rem;
  }
}

@media screen and (max-device-width:768px) {
    *{
        font-size:0.8rem;
        font-weight: bold;
    }
}