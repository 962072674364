.addnew{
    padding: 1rem 2rem
}
.sigCanvas{
    border: 1px solid #dfd3d3;
    width: 100%;
    height: 20rem;
    background-color: white;
}
.error{
    color: red;
    font-size: 0.8rem;
    font-weight: bold;
    margin-top: 0.5rem;
}