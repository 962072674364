.therapist{
    padding: 2rem 1rem;
}
.sigCanvas{
    border: 1px solid #dfd3d3;
    width: 100%;
    height: 20rem;
    background-color: white;
}
.modal-error{
    color: red;
    font-size: 0.8rem;
    margin-top: 0.5rem;
    font-weight: bold;
}